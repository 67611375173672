html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* チャット画面の背景色 */
.cs-main-container > .cs-chat-container > .cs-message-list {
  height: 100%;
  background-color: #efefef;
}

/* チャット画面のテキスト部分の背景色 */
section.cs-message > .cs-message__content-wrapper > .cs-message__content {
  background-color: white !important;
}

section.cs-message-me > .cs-message__content-wrapper > .cs-message__content {
  background-color: #c9e4ba !important;
}

img {
  width: 100%;
}
